import React from 'react';
import './About.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Text from '../../Data/data';
import Lottie from 'react-lottie';
import DTAnimation1 from '../../Data/drawkit-grape-animation-2-LOOP.json';

const About = () =>{

    const DT1 ={
        loop: true,
        autoplay: true,
        animationData: DTAnimation1,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };

    return(
    <div id='about' data-aos='fade-down'>
            <Container>
                <Row>
                    <Col md={{span:6}}>
                            <h2>{Text.about.title} 
                                    <div className='highlights'>{Text.about.title2}</div> 
                                    </h2>
                            <p>{Text.about.desc1}</p>
                            <p>{Text.about.desc2}</p>
                        </Col>
                        <Col md={{span:5, offset: 1}}>
                            <div className='graphics1'>
                                <Lottie options={DT1}/>
                            </div>
                    </Col>
                </Row>
            </Container>
    </div>);
}

export default About;