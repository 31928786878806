import React from "react";
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Brand from '../../Images/Icons/grey_nobg.png';
import Instagram from '../../Images/Icons/001-instagram-sketched.png';
import Linkedin from '../../Images/Icons/002-linkedin.png';
import './Footer.css';

const Footer = () => {
    const year = new Date().getFullYear();
    return (
          <footer>
              <Container id='Footer-style'>
            <Row>
            <Col md={4} >
                  <Row>
                    <Col>
                        <h6>Let's stay connected!</h6>
                          <a href="https://www.instagram.com/kennylws/" target="_blank" rel="noopener noreferrer"><img alt="instagram" className="Social-Links-Icon" src={Instagram} height="40" width="40" /></a>
                          <a href="https://www.linkedin.com/in/kenny-lim-1854a4101/" target="_blank" rel="noopener noreferrer"><img alt="linkedin" className="Social-Links-Icon" src={Linkedin} height="40" width="40" /></a>
                          <h6>A project by me</h6>
                          <p style={{color: "#b8c1df"}}>A platform to express my thoughts, ideas and to showcase my work <img src={Brand} alt='the-mountain' width='16px'/> </p>
                          <p><strong>Copyright </strong>ⓒ {year} Kennylws</p>
                    </Col>
                  </Row>
                </Col>

                <Col md={{offset: 1, span: 4}} className='footer-menu'>
                <h6>Site links</h6>
                <ul style={{listStyleType: "none"}}>
                  <li><a href="/#about">About</a></li>
                  <li><a href="/#testimonials">Testimonials</a></li>
                  <li><a href="/#contact">Get in Touch</a></li>
                </ul>
              </Col>

                <Col md={3} >
              
              <p>Disclaimer :
              <a href="https://www.finexis.com.sg/website-disclaimer.html" target="_blank" rel="noopener noreferrer"> https://www.finexis.com.sg/website-disclaimer.html</a>
              </p>
              <p>Privacy Policy :
              <a href="https://finexis.com.sg/privacy.html" target="_blank" rel="noopener noreferrer"> https://finexis.com.sg/privacy.html</a>
              </p>
              <p>Fair Dealing :
              <a href="https://finexis.com.sg/fairdealing.html" target="_blank" rel="noopener noreferrer"> https://finexis.com.sg/fairdealing.html</a>
              </p>
              <p>Kenny Lim is an authorised Financial Consultant representing finexis advisory Pte Ltd [Reg No. 200408660G].</p>
            </Col>
            </Row>
            <Row>
              
            </Row>
            </Container>
          </footer>
        )
}
export default Footer;