import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Text from '../../Data/data';
import { InlineWidget } from 'react-calendly';
import './Contact.css';
//import Lottie from 'react-lottie';
//import DTAnimation1 from '../../Data/drawkit-grape-animation-5-LOOP.json';

const Contact = () =>{
    const [formType, setFormType] = useState(Text.contact.financialReview.title);
    const [clicked, setClicked] = useState(true);

    /*const DT1 ={
        loop: true,
        autoplay: true,
        animationData: DTAnimation1,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      }; 
      //lottie animation
      */

    const ContactFormType = (props) =>{
        return(<div className={props.name === formType ? 'form-category-selected' : 'form-category'} onClick={()=>{
            setClicked(true);
            setFormType(props.name);
        }
        }>
            <h4 className={props.name === formType ? 'selected' : 'notSelected'} >{props.name}</h4>
            <p>{props.desc}</p>
        </div>);
    }

    const ContactForm = () =>{
        if(formType === Text.contact.financialReview.title){
            return(<div className='form' data-aos="fade-right"  data-aos-duration="100000"  data-aos-offset="0">
                <InlineWidget url="https://calendly.com/kennylws/consultation" 
                    prefill={{customAnswers: {a2: 1}}} 
                    styles={{height: "1050px"}}
                />
            </div>)
        }else if(formType === Text.contact.newInvestment.title){
            return(<div data-aos="fade-right"  data-aos-duration="100000"data-aos-offset="0">
                <InlineWidget url="https://calendly.com/kennylws/consultation" 
                    prefill={{customAnswers: {a2: 2}}} 
                    styles={{height: "1050px"}}
                />
            </div>);
        }else if(formType === Text.contact.newInsurance.title){
            return(<div data-aos="fade-right"  data-aos-duration="100000"data-aos-offset="0">
                <InlineWidget url="https://calendly.com/kennylws/consultation" 
                    prefill={{customAnswers: {a2: 3}}} 
                    styles={{height: "1050px"}}
                />
            </div>);
        }else{
            return(<div></div>);
        }
    }

    

    return(<div data-aos="fade-up" data-aos-offset="200" id='contact'>
        
        <Container>
        <Row>
            <Col>
                <div className="title">
                    <h2>{Text.contact.title}
                    <div className='highlights'>{Text.contact.title2}</div>
                    {Text.contact.title3}
                    </h2>
                    <h4>{Text.contact.desc}</h4>
                </div>
            </Col>
        </Row>
        <Row>
            <Col className="form-type" md={6}>
                {clicked ? <ContactForm /> : null }        
            </Col>
            <Col md={{ span: 6, offset: 0 }} className="d-none d-sm-block">
                
                <ContactFormType name={Text.contact.financialReview.title} desc={Text.contact.financialReview.desc}/>
                <ContactFormType name={Text.contact.newInvestment.title} desc={Text.contact.newInvestment.desc}/>
                <ContactFormType name={Text.contact.newInsurance.title} desc={Text.contact.newInsurance.desc}/>
            </Col>
        </Row>
        
        </Container>
    </div>);
}

export default Contact;