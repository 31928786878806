import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Testimonial from '../../Data/testimonials';
import Quote from '../../Images/Icons/quote.png';
import NarendiranPillay from '../../Images/Testimonials/NarendiranPillay.png';
import YapRiHao from '../../Images/Testimonials/YapRiHao.png';
import HuangWeiFeng from '../../Images/Testimonials/HuangWeiFeng.png';
import './Testimonials.css';


const Testimonials = () =>{

    return(<div id='testimonials' data-aos="zoom-in-up"> 
                <Container flex>
                    <Row>
                        <Col>
                            <h2>{Testimonial.Title}
                            <div className='highlights'>{Testimonial.Title2}</div>
                            {Testimonial.Title3}
                            </h2>
                        </Col>
                    </Row>
                    <Row xs={1} md={1} lg={3}>
                        <Col className="cardflex">
                        <Card className="testimonial-card" >
                            <Card.Body> 
                                <img alt='quote' className="quote" src={Quote}/>
                                <Card.Text className="text-center">{Testimonial.NarendiranPillay.testimonial}<br/><br/><br/></Card.Text>
                                <img alt='client' className="testimonialImage" src={NarendiranPillay}/>
                                <Card.Title>{Testimonial.NarendiranPillay.name}</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">{Testimonial.NarendiranPillay.job}</Card.Subtitle>
                            </Card.Body>
                        </Card>
                        </Col>
                        <Col className="cardflex">
                        <Card className="text-center" >
                            <Card.Body> 
                                <img alt='quote' className="quote" src={Quote}/>
                                <Card.Text className="text-center">{Testimonial.HuangWeiFeng.testimonial}</Card.Text>
                                <img alt='client' className="testimonialImage" src={HuangWeiFeng}/>
                                <Card.Title>{Testimonial.HuangWeiFeng.name}</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">{Testimonial.HuangWeiFeng.job}</Card.Subtitle>
                            </Card.Body>
                        </Card>
                        </Col>
                        <Col className="cardflex">
                        <Card className="text-center" >
                            <Card.Body> 
                                <img alt='quote' className="quote" src={Quote}/>
                                <Card.Text className="text-center">{Testimonial.YapRiHao.testimonial}</Card.Text>
                                <img alt='client' className="testimonialImage" src={YapRiHao}/>
                                <Card.Title>{Testimonial.YapRiHao.name}</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">{Testimonial.YapRiHao.job}</Card.Subtitle>
                            </Card.Body>
                        </Card>
                        </Col>  
                    </Row>            
                </Container>
    </div>)
}

export default Testimonials;