import React from 'react';
import './Header.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

export const Header = () =>{
    return(<div id="Header" >
            <Navbar className='menu'  collapseOnSelect  expand="lg" variant="light">
            <Container>
                    <Navbar.Brand href="/#home"><h5 style={{color: "#576585"}}><b>Financial Planning 4 You</b></h5></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    
                    <Navbar.Collapse id="responsive-navbar-nav" className='justify-content-end'>
                        <Nav>
                        <Nav.Item>
                                <Nav.Link href="/#about"><h5>About</h5></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="/#testimonials"><h5>Testimonials</h5></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="/#contact"><h5>Get in Touch</h5></Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                    </Container>
            </Navbar>
        
    </div>)
}

export const BlogHeader = () =>{
    return(<div id="BlogHeader" >
            <Navbar className='menu' fixed="top" collapseOnSelect  expand="lg" variant="light">
            <Container>
            <Navbar.Brand href="#home"><h5 style={{color: "#576585"}}><b>Financial Planning 4 Everyone</b></h5></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            
            <Navbar.Collapse id="responsive-navbar-nav" className='justify-content-end'>
                <Nav>
                    <Nav.Item>
                        <Nav.Link href="/">Back</Nav.Link>
                    </Nav.Item>
                </Nav>
            </Navbar.Collapse>
            </Container>
            </Navbar>
        
    </div>)
}
