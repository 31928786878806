import React from 'react';
import Text from '../../Data/data';
import profilePicture from '../../Images/Profile Picture.png';
import './Home.css';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Lottie from 'react-lottie';
//import DTAnimation1 from '../../Data/drawkit-grape-animation-8-LOOP.json';
import DTAnimation2 from '../../Data/drawkit-grape-animation-4-LOOP.json';

const Home = () =>{
/*
    const handleClickService = (e) =>{
        e.preventDefault();
        window.location.href='#services';
    }

    const handleClickContact = (e) =>{
        e.preventDefault();
        window.location.href='#contact';
    }
     <Row style ={{margin: "13% -8% 7%"}}>
                        <Col md={9} style={{textAlign: "left"}} xs={12}>
                            <div className='about' onClick={handleClickService}>
                                <p>{Text.header.title}</p>
                            </div>
                        </Col>

                    </Row>
                    <Row style={{paddingBottom: "10%"}}>
                        <Col md={{ span: 5, offset: 7 }} xs={12}>
                            <button className='appointment' onClick={handleClickContact} variant="light">Lets meet!</button>
                        </Col>
                    </Row>

const DT1 ={
    loop: true,
    autoplay: true,
    animationData: DTAnimation1,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };*/
  const DT2 ={
    loop: true,
    autoplay: true,
    animationData: DTAnimation2,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  
        return(<div id="home" >
                    
                    <Container data-aos="zoom-in">
                        <Row>
                            <Col xs={12} md={{ span: 6, offset: 1 }} style={{alignSelf: "center"}} >
                                    <div className='introduction'>
                                        <h2>{Text.introduction.subTitle} 
                                        <div className='highlights'>{Text.introduction.subTitle2}</div> 
                                        {Text.introduction.subTitle3} 
                                        <div className='highlights'>{Text.introduction.subTitle4}</div> </h2>
                                    </div>
                                </Col>
                            <Col md={5} style={{alignSelf: "center"}}  className="d-none d-lg-block">
                                <div className='graphics1'>
                                    <img alt='ProfilePicture' className="profileImage" src={profilePicture}/>
                                </div>
                            </Col>
                        </Row>
                            <Row>
                                <Col xs={12} md={5} style={{alignSelf: "flex-start"}}>
                                    <div className='graphics2'>
                                        <Lottie options={DT2}/>
                                    </div>
                                </Col>
                                <Col xs={12} md={{ span: 6, offset: 1 }}  style={{alignSelf: "center"}}  >
                                    <div className='introduction-text'>
                                        <p>
                                            {Text.introduction.desc1}
                                            <div className='highlights'>{Text.introduction.highlight1}</div>
                                            {Text.introduction.desc2}
                                            <div className='highlights'>{Text.introduction.highlight2}</div>
                                            {Text.introduction.desc3}
                                            <div className='highlights'>{Text.introduction.highlight3}</div>
                                            {Text.introduction.desc4}
                                        </p>
                                        <p>{Text.introduction.desc6}</p>
                                    </div>
                                </Col>
                            </Row>
                    </Container>
                    
                </div>);
}

export default Home;