import React, { useEffect }from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import './App.css';

import About from '../About/About';
import Footer from '../Footer/Footer';
import Contact from '../Contact/Contact';
import Testimonials from '../Testimonials/Testimonials';
import Home from '../Home/Home';
import { Header } from '../Header/Header';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {

  useEffect(() => {
    AOS.init({
      duration : 1500,
      once: true
    })
  });
  
  return (
    <div className="App">
    <Router>
      <Switch>
        <Route exact path="/">
        <Header />
        <Home />
        <About />
        <Testimonials />
        <Contact />
        <Footer />
        </Route>
      </Switch>
    </Router>
    </div>
  );
}

export default App;
